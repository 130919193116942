import { isPlatformBrowser } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, PLATFORM_ID, Renderer2 } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CanonicalService } from '../../../services/canonical.service';
import { ContactUsComponent } from '../../contact-us/contact-us.component';
import { OdooServicesComponent } from "../odoo-services/odoo-services.component";
import { FaqComponent } from "../../faq/faq.component";

@Component({
  selector: 'app-manufacturing',
  standalone: true,
  imports: [OdooServicesComponent, FaqComponent],
  templateUrl: './manufacturing.component.html',
  styleUrl: './manufacturing.component.scss'
})
export class ManufacturingComponent {

  odooManufacturing = [
    {
      icon: "assets/images/odoo/manufacturing/Production_Management.svg",
      service_name: "Production Management",
      description: "Simplify production processes with Odoo’s easy-to-use Manufacturing Orders (MO), Work Orders, and Bill of Materials (BoM). You can track production status in real time and manage multi-level BoMs with ease."
    },
    {
      icon: "assets/images/odoo/manufacturing/Real_Time_Scheduling.svg",
      service_name: "Real-Time Scheduling",
      description: "Utilize Odoo’s advanced scheduling tools to balance production demand with resource availability. Features like the MRP Scheduler automatically adjust production priorities based on changing inventory levels, sales, and work center capacity."
    },
    {
      icon: "assets/images/odoo/manufacturing/Quality_Control.svg",
      service_name: "Quality Control",
      description: "Odoo allows you to set up detailed Quality Checks at any stage of production. With built-in triggers and alerts, your team can ensure products meet quality standards before moving forward."
    },
    {
      icon: "assets/images/odoo/manufacturing/Inventory_Integration.svg",
      service_name: "Inventory Integration",
      description: "Seamlessly integrate with Odoo’s Inventory module to keep your stock levels up to date. Odoo Manufacturing synchronizes material consumption and production data, helping you maintain a balanced inventory and avoid costly stockouts or overproduction."
    },
    {
      icon: "assets/images/odoo/manufacturing/Maintenance_Management.svg",
      service_name: "Maintenance Management",
      description: "Implement preventative and corrective maintenance easily with Odoo’s Maintenance app. By scheduling machine checkups or repairs based on production data, you can minimize downtime and avoid unexpected breakdowns."
    },
    {
      icon: "assets/images/odoo/manufacturing/Traceability.svg",
      service_name: "Traceability",
      description: "Track every step of the manufacturing process from raw materials to finished products with Lot and Serial Number Tracking. This ensures total transparency and traceability for compliance or quality audits."
    },
    {
      icon: "assets/images/odoo/manufacturing/Cost_Analysis.svg",
      service_name: "Cost Analysis",
      description: "Accurately calculate production costs with Odoo’s Costing feature. From raw materials to labor, you can analyze every cost factor to make data-driven decisions that improve profitability."
    },
    {
      icon: "assets/images/odoo/manufacturing/IoT_Integration.svg",
      service_name: "IoT Integration",
      description: "Leverage the power of IoT (Internet of Things) by connecting your production equipment with Odoo’s IoT Box. Automate data capture from machines to improve accuracy and efficiency in production processes."
    },
    {
      icon: "assets/images/odoo/manufacturing/Customization_and_Scalability.svg",
      service_name: "Customization and Scalability",
      description: "Every manufacturing business is unique, and Odoo allows for high customization to meet your specific needs. Whether it's customizing workflows, adding fields, or integrating with third-party apps, Odoo adapts as your business grows."
    }
  ];

  faqData = [
    {
      title: "What is Odoo Manufacturing, and how can it benefit my business?",
      content: "Odoo Manufacturing is an ERP module that helps you manage and streamline all aspects of your production process, from creating manufacturing orders to tracking product quality. It integrates seamlessly with other Odoo apps like inventory and maintenance, offering real-time insights and helping you reduce costs and improve efficiency."
    },
    {
      title: "Can Odoo Manufacturing handle complex production workflows?",
      content: "Yes! Odoo Manufacturing supports multi-level Bill of Materials (BoM), work orders, and routing capabilities. It is designed to handle both simple and complex manufacturing processes, offering flexibility and scalability for growing businesses."
    },
    {
      title: "How does Odoo Manufacturing integrate with inventory management?",
      content: "Odoo Manufacturing is tightly integrated with Odoo Inventory, allowing you to track material consumption, update stock levels in real time, and automate replenishment processes. This ensures you always have the right materials in stock for production without overstocking or running out."
    },
    {
      title: "Does Odoo Manufacturing support quality control?",
      content: "Yes, Odoo Manufacturing comes with built-in Quality Control tools. You can set up quality checks at different stages of the manufacturing process, ensuring that your products meet the required standards before they are delivered to customers."
    },
    {
      title: "How can IoT (Internet of Things) improve my manufacturing operations?",
      content: "With Odoo IoT, you can connect production equipment directly to your Odoo system. This allows you to automate data collection, improve accuracy, and optimize machine performance by monitoring real-time data from your equipment."
    },
    {
      title: "Is Odoo Manufacturing suitable for small and medium-sized businesses (SMBs)?",
      content: "Absolutely. Odoo Manufacturing is scalable, meaning it works just as well for small businesses as it does for large manufacturers. It is flexible, affordable, and easy to customize to suit your unique manufacturing processes."
    },
    {
      title: "How does AttributeX help with Odoo Manufacturing implementation?",
      content: "As an official Odoo Partner, AttributeX provides end-to-end Odoo Manufacturing implementation services. We help you with customization, integration, training, and ongoing support to ensure that the system meets your specific production needs and grows with your business."
    },
    {
      title: "Can Odoo Manufacturing track production costs?",
      content: "Yes, Odoo’s Costing feature allows you to accurately calculate all aspects of your production costs, including raw materials, labor, and overhead. This data helps you optimize production processes and improve profitability."
    },
    {
      title: "How can I schedule a demo with AttributeX?",
      content: "To schedule a free consultation or demo of Odoo Manufacturing, simply contact AttributeX via our website or phone. We will walk you through the platform’s key features and demonstrate how it can be tailored to meet your business’s specific needs."
    }
  ];



  currentUrl: any;


  constructor(
    private modalService: NgbModal,
    @Inject(PLATFORM_ID) private platformId: any,
    private renderer: Renderer2,
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title,
    private meta: Meta,
    private _changeDetectorRef: ChangeDetectorRef,
    private canonicalService: CanonicalService
  ) { }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo(0, 0);
      this.currentUrl = window.location.href;

      this.route.url.subscribe(urlSegments => {
        this.canonicalService.setCanonicalUrl(this.currentUrl);
      });
    }

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.updateMetaTags();
      }
    });

    this.updateMetaTags();
  }

  updateMetaTags() {
    const routeWithoutFirstCharacter = this.router.url;
    const currentRoute = routeWithoutFirstCharacter.split('/').pop();

    if (currentRoute == 'manufacturing') {
      this.titleService.setTitle('Odoo Manufacturing ERP Solutions | Optimize Production with AttributeX');
      this.meta.updateTag({ name: 'description', content: "Streamline your manufacturing operations with Odoo Manufacturing. AttributeX, an official Odoo partner, helps you customize and implement powerful Odoo ERP features like production management, real-time scheduling, inventory integration, and IoT. Contact us today for a demo!" });
      this.meta.updateTag({ name: 'title', content: 'Odoo Manufacturing ERP Solutions | Optimize Production with AttributeX' });
      this.meta.updateTag({ name: 'keywords', content: 'Odoo Manufacturing, Odoo ERP for manufacturing, Odoo production management, Odoo MRP system, manufacturing automation, Odoo quality control, Odoo inventory integration, Odoo partner, AttributeX' });
    }

    // Trigger change detection to update the view
    this._changeDetectorRef.detectChanges();
  }

  // openModal() {
  //   this.modalService.open(ContactUsComponent, {
  //     windowClass: 'custom-modal-lg',
  //   });
  // }

  odooForm() {
    window.open('/odoo-form', '_blank');
  }
}
