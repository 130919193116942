import { CommonModule, isPlatformBrowser } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, PLATFORM_ID } from '@angular/core';
import { ContactUsComponent } from '../contact-us/contact-us.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { SliderComponent } from '../slider/slider.component';
import { CanonicalService } from '../../services/canonical.service';

@Component({
  selector: 'app-service',
  standalone: true,
  imports: [CommonModule, SliderComponent],
  templateUrl: './service.component.html',
  styleUrl: './service.component.scss'
})
export class ServiceComponent {
  dynamicContent: any;

  servicesContent: any = {
    contact_heading: 'Elevate Your Business Operations',
  }

  staffingContent: any = {
    contact_heading: 'Partner with Us for Your Staffing Needs',
    contact_description: 'Let us help you assemble a powerhouse team that’s equipped to leverage the full potential of your technology investments. Reach out to us for staffing solutions that are as innovative as they are effective.',

  }

  consultingContent: any = {
    contact_heading: 'Ready to Transform?',
    contact_description: 'Connect with our experts today and discover how our consulting and advisory services can lead to unparalleled growth and innovation for your business.',
  }

  technologyContent: any = {
    contact_heading: 'Accelerate Your Digital Journey',
    contact_description: 'Ready to drive growth, innovation, and efficiency with our tailored technology services? Contact us today and take the first step towards a smarter, more connected future.',

  }




  currentUrl: any;
  currentRoute: any;
  getData: any = [];
  isData: boolean = false;
  resData: any;

  constructor(
    private modalService: NgbModal,
    private titleService: Title,
    private meta: Meta,
    private _router: Router,
    private apiService: ApiService,
    @Inject(PLATFORM_ID) private platformId: any,
    private _changeDetectorRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    private canonicalService: CanonicalService
  ) { }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo(0, 0);
      this.currentUrl = window.location.href;

      

      this.route.url.subscribe(urlSegments => {
        // const currentUrl = window.location.origin + this.route.snapshot.url.join('/');
        this.canonicalService.setCanonicalUrl(this.currentUrl);
    });
    }
    // this.titleService.setTitle('Services - AttributeX');
    // this.meta.updateTag({ name: 'description', content: 'AttributeX develops and implements operational and technology improvement strategies to reduce operational costs, optimize business processes, reduce management risk and enhance portfolio value for our clients.' });
    // this.meta.updateTag({ name: 'title', content: 'Streamline Financial Reporting with AttributeX | Expert Remediation Services' });
    // this.meta.updateTag({ name: 'keywords', content: 'Financial Reporting Remediation' });
    // this.meta.updateTag({ rel: 'canonical', href: this.currentUrl });
    
    const routeWithoutFirstCharacter = this._router.url;
    this.currentRoute = routeWithoutFirstCharacter.split('/').pop();
    // console.log(this.currentRoute);
    
    
    if (this.currentRoute == 'managed-services') {
      this.dynamicContent = this.servicesContent;
      this.titleService.setTitle('Managed Services Delivery | Attributex');
      this.meta.updateTag({ name: 'description', content: 'Our Managed services focus on clients strategy, marketing, organization, operations, technology, transformation, digital, advanced analytics, corporate finance, mergers & acquisitions and across all industries.' });
      this.meta.updateTag({ name: 'title', content: 'Managed Services Delivery | Attributex' });
      this.meta.updateTag({ name: 'keywords', content: 'Managed Services Delivery' });
      // this.meta.updateTag({ rel: 'canonical', href: this.currentUrl });
      this._changeDetectorRef.detectChanges();

    } else if (this.currentRoute == 'staffing') {
      this.dynamicContent = this.staffingContent;
      this.titleService.setTitle('Staffing Services - Attributex');
      this.meta.updateTag({ name: 'description', content: 'Empower your tech projects with the right talent! Attributex Staffing Services, offering skilled professionals for your specific technology needs for Strategic & Operations services, Recruitment and staffing services, and HR audit & Compliance services.' });
      this.meta.updateTag({ name: 'title', content: 'Staffing Services | Attributex' });
      this.meta.updateTag({ name: 'keywords', content: 'Staffing Services' });
      // this.meta.updateTag({ rel: 'canonical', href: this.currentUrl });
      this._changeDetectorRef.detectChanges();

    } else if (this.currentRoute == 'technology') {
      this.dynamicContent = this.technologyContent;
      this.titleService.setTitle('Enterprise Technology Services - Attributex');
      this.meta.updateTag({ name: 'description', content: "Discover Attributex's IT consulting services: from strategic IT roadmaps to cutting-edge solutions, we're your partner in innovation and tech excellence." });
      this.meta.updateTag({ name: 'title', content: 'Enterprise Technology Services | Attributex' });
      this.meta.updateTag({ name: 'keywords', content: 'Enterprise Technology Services' });
      // this.meta.updateTag({ rel: 'canonical', href: this.currentUrl });
      this._changeDetectorRef.detectChanges();

    } else if (this.currentRoute == 'consulting') {
      this.dynamicContent = this.consultingContent;
      this.titleService.setTitle('Consulting Services - Attributex');
      this.meta.updateTag({ name: 'description', content: 'Attributex is a global business consulting firm providing IT consulting, Risk & Compliance, Digital Transformation, Internal Audit services.' });
      this.meta.updateTag({ name: 'title', content: 'Consulting Services | Attributex' });
      this.meta.updateTag({ name: 'keywords', content: 'Consulting Services' });
      // this.meta.updateTag({ rel: 'canonical', href: this.currentUrl });
      this._changeDetectorRef.detectChanges();
    }

    this.fetchData();
  }



  fetchData() {
    this.apiService.get<any>(`/service/list?page_route=${this.currentRoute}`).subscribe((response) => {
      if (response) {
        this.resData = response?.data?.data;

        this.resData?.forEach((element: any) => {
          if (element?.service_res.length > 0) {
            this.getData.push(element)
            this.isData = true;
          }

        });
        this._changeDetectorRef.detectChanges();
      }
    });
  }

  openModal() {
    this.modalService.open(ContactUsComponent, {
      windowClass: 'custom-modal-lg',
    });
  }


}
