import { CommonModule, isPlatformBrowser } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaSettings, RecaptchaV3Module, ReCaptchaV3Service } from 'ng-recaptcha';
import Swal from 'sweetalert2';
import { ApiService } from '../../../services/api.service';
import { ActivatedRoute, NavigationEnd, Router, RouterModule } from '@angular/router';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { CaptchaComponent } from '../../captcha/captcha.component';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { Subject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Title, Meta } from '@angular/platform-browser';
import { CanonicalService } from '../../../services/canonical.service';

@Component({
  selector: 'app-odoo-form',
  standalone: true,
  imports: [CommonModule, RouterModule, ReactiveFormsModule, FormsModule, MatFormFieldModule, MatInputModule, MatSelectModule, MatOptionModule, CaptchaComponent, RecaptchaV3Module],
  templateUrl: './odoo-form.component.html',
  styleUrl: './odoo-form.component.scss',
  providers: [
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: {
        siteKey: environment.recaptcha.SITE_KEY,
      } as RecaptchaSettings,
    },
  ],
})
export class OdooFormComponent implements OnInit, OnDestroy {
  formData: FormGroup;
  currentRoute: any;
  receivedToken: string = '';
  getIndustryType: any;
  sizeOfCompany: any;
  serviceRequired: any;
  currentUrl: any;
  private destroy$ = new Subject<void>();


  constructor(
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private modalService: NgbModal,
    private router: Router,
    private recaptchaV3Service: ReCaptchaV3Service,
    private route: ActivatedRoute,
    private titleService: Title,
    private _changeDetectorRef: ChangeDetectorRef,
    private meta: Meta,
    @Inject(PLATFORM_ID) private platformId: any,
    private CanonicalService: CanonicalService
  ) {
    // const routeWithoutFirstCharacter = this.router.url;
    // this.currentRoute = routeWithoutFirstCharacter.split('/').pop();

    // if (this.currentRoute.includes('blogs/')) {
    //   this.currentRoute = 'blogs';
    // }

    // if (this.currentRoute == '') {
    //   this.currentRoute = 'home';
    // }

    this.getIndustryType = ['Manufacturing', 'Healthcare', 'Software', 'Retail', 'Other'];
    this.sizeOfCompany = ['Small', 'Medium', 'Large'];
    this.serviceRequired = ['All Odoo Services', 'Odoo Customization', 'Odoo Implementation', 'Odoo Integration'];

    this.formData = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$'),],],
      phone_number: ['', [Validators.required, Validators.pattern('^[0-9]{10}$')],],
      industry_type: ['', [Validators.required]],
      size_of_company: ['', [Validators.required]],
      services_required: ['', [Validators.required]],
      message: ['', Validators.required],
      'g-recaptcha-response': ['']
    });
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo(0, 0);
      this.currentUrl = window.location.href;

      this.route.url.subscribe(urlSegments => {
        this.CanonicalService.setCanonicalUrl(this.currentUrl);
      });
    }

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.updateMetaTags();
      }
    });

    this.updateMetaTags();
  }

  onSubmit() {
    if (this.formData.invalid) {
      Object.keys(this.formData.controls).forEach(control => {
        this.formData.controls[control].markAsTouched();
      });
      Swal.fire('Oops', 'Kindly fill all the fields', 'error');
      return;
    } else {
      this.recaptchaV3Service.execute('importantAction')
        .subscribe((token: string) => {
          this.receivedToken = token;
          this.formData.patchValue({
            'g-recaptcha-response': this.receivedToken
          });

          const serializedForm = this.apiService.serializeForm(this.formData.value);

          this.apiService
            .post<any>('/lead_master/create', serializedForm)
            .subscribe((response) => {
              if (response?.data.status) {
                // setTimeout(() => {
                //   if (isPlatformBrowser(this.platformId)) {
                //     window.scrollTo(0, 0);
                //   }
                // }, 100);
                Swal.fire('Success', `${response?.data?.message}`, 'success').then(
                  () => {
                    this.router.navigateByUrl('/odoo-success')
                  }
                );
              } else {
                Swal.fire('Error', `${response?.data?.message}`, 'error');
              }
            });
        });
    }
  }

  updateMetaTags() {
    const routeWithoutFirstCharacter = this.router.url;
    const currentRoute = routeWithoutFirstCharacter.split('/').pop();

    if (currentRoute == 'odoo-form') {
      this.titleService.setTitle('Fill the form to Contact Odoo Experts at AttributeX');
      this.meta.updateTag({ name: 'description', content: "Contacts Us or Schedule a video conference +1 (858)-304-8004 Meet an expert To assess your project & get a tailored demo" });
      this.meta.updateTag({ name: 'title', content: 'Fill the form to Contact Odoo Experts at AttributeX' });
      this.meta.updateTag({ name: 'keywords', content: 'odoo form' });
    }

    // Trigger change detection to update the view
    this._changeDetectorRef.detectChanges();
  }


  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
