import { Component, CUSTOM_ELEMENTS_SCHEMA, EventEmitter, Output } from '@angular/core';
import { RecaptchaV3Module } from 'ng-recaptcha';

@Component({
  selector: 'app-captcha',
  standalone: true,
  imports: [RecaptchaV3Module],
  templateUrl: './captcha.component.html',
  styleUrls: ['./captcha.component.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CaptchaComponent {
  @Output() dataEvent = new EventEmitter();
  captchaResponse: any;

  resolved(captchaResponse: any) {
    this.captchaResponse = captchaResponse;
    this.dataEvent.emit(this.captchaResponse);
    console.log(`Resolved captcha with response: ${captchaResponse}`);
  }

  errored(event: any) {
    console.warn(`reCAPTCHA error encountered`, event);
  }
}
