import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})

export class ApiService {
  API_BASE_URL = environment.API_BASE_URL;
  API_KEY = environment.API_KEY;

  private axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: this.API_BASE_URL,
      headers: {
        'x-api-key': `${this.API_KEY}`,
      },
    });
  }

  public serializeForm(data: any): string {
    const formBody = [];
    for (const property in data) {
      const encodedKey = encodeURIComponent(property);
      const encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + '=' + encodedValue);
    }
    return formBody.join('&');
  }

  public get<T>(endpoint: string): Observable<AxiosResponse<T>> {
    return new Observable<AxiosResponse<T>>((observer) => {
      this.axiosInstance
        .get<T>(endpoint)
        .then((response) => {
          observer.next(response);
          observer.complete();
        })
        .catch((error) => {
          observer.error(error);
        });
    });
  }

  public post<T>(endpoint: string, data: any): Observable<AxiosResponse<T>> {
    return new Observable<AxiosResponse<T>>((observer) => {
      this.axiosInstance
        .post<T>(endpoint, data)
        .then((response) => {
          observer.next(response);
          observer.complete();
        })
        .catch((error) => {
          observer.error(error);
        });
    });
  }

  // getPosts(): Observable<any[]> {
  //   return this.http.get<any[]>(`${this.apiUrl}/contact_us_request/list`);
  // }

  // getPostById(id: number): Observable<any> {
  //   return this.http.get<any>(`${this.apiUrl}/posts/${id}`);
  // }

  // addPost(post: any): Observable<any> {
  //   return this.http.post<any>(`${this.apiUrl}/posts`, post);
  // }

  // updatePost(id: number, post: any): Observable<any> {
  //   return this.http.put<any>(`${this.apiUrl}/posts/${id}`, post);
  // }

  // deletePost(id: number): Observable<any> {
  //   return this.http.delete<any>(`${this.apiUrl}/posts/${id}`);
  // }
}
