<app-slider *ngIf="isData"></app-slider>


<ng-container *ngIf="isData">
    <div class="third-section" *ngFor="let data of getData">
        <div class="container">
            <div class="heading mb-4 pb-2 ps-2">{{data?.category}}</div>

            <div class="row m-0">
                <div class="col-md-4" *ngFor="let card of data?.service_res;let index as index">
                    <div class="d-flex gap-3 service_section_card h-100">
                        <div>
                            <img [src]="card?.icon" [alt]="'icon' + (index + 1)">
                        </div>
                        <div class="services_card">
                            <div class="heading mb-1">
                                {{card?.service_name}}
                            </div>
                            <div class="content" [title]="card?.description">
                                {{card?.description}}
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>
</ng-container>
<!-- <div class="third-section pt-0">

    <div class="container">
        <div class="heading mb-4 pb-2 ps-2">{{dynamicContent?.services_heading2}}</div>

        <div class="row m-0 mb-5">
            <div class="col-md-4" *ngFor="let data of dynamicContent?.operationalAgility; let index as index">
                <div class="d-flex gap-3 service_section_card h-100">
                    <div>
                        <img [src]="data?.imgSrc">
                    </div>
                    <div class="services_card">
                        <div class="heading mb-1">
                            {{data?.heading}}
                        </div>
                        <div class="content">
                            {{data?.content}}
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
</div> -->

<div class="p-3 p-md-5 bg-white" *ngIf="isData">
    <div class="container">
        <div class="contact_section">
            <div class="row">
                <div class="col-md-6">
                    <div class="elevate_text mb-3 mb-md-0">{{dynamicContent?.contact_heading}}</div>
                </div>
                <div class="col-md-6 pb-3" *ngIf="dynamicContent?.contact_description">
                    <div class="elevate_description mb-3 mb-md-0">
                        {{dynamicContent?.contact_description}}</div>
                </div>
                <div class="col-md-6" [ngClass]="dynamicContent?.contact_description ? 'text-start':'text-end'">
                    <button class="contact_btn me-md-5 pe-md-5" (click)="openModal()">Contact us for more
                        information</button>
                </div>
            </div>
        </div>
    </div>
</div>