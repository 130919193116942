<div class="position-relative">
    <img src="assets/images/web_analytics/web_analytics_banner.webp" width="100%" alt="web analytics banner">
    <button class="learn_more_btn" (click)="openModal()">Learn More</button>
</div>

<div class="text-center second_section">
    <h1 class="heading">Elevate Your Business with Web Analytics Expertise from AttributeX</h1>
    <div class="description description_2">Discover how AttributeX Web Analytics Solutions can empower your business
        with actionable insights to optimize performance and enhance customer engagement. With tools like Google
        Analytics and Adobe Analytics, we provide the expertise to transform your digital presence and drive
        data-informed decisions.
    </div>
</div>

<app-odoo-services [buttonShow]="false" [odooPartner]="odooPartner"
    [heading]="'Why Web Analytics Matters'"></app-odoo-services>
<app-odoo-services [bgColor]="bgColor" [bgCardColor]="bgCardColor" [odooPartner]="WebAnalyticsServices"
    [heading]="'Our Web Analytics Services'"></app-odoo-services>
<app-odoo-services [odooPartner]="WhyChooseAttributeX" [heading]="'Why Choose AttributeX ?'"></app-odoo-services>
<app-odoo-services [bgColor]="bgColor" [bgCardColor]="bgCardColor" [odooPartner]="successStories" [heading]="'Success Stories'"></app-odoo-services>


<div class="text-center second_section">
    <h2 class="heading mb-0">Take the Next Step</h2>
    <div class="description ">Empower your business with web analytics that deliver results. Partner with AttributeX to
        unlock the full potential of tools like Google Analytics and Adobe Analytics. Let’s turn data into your
        competitive advantage.
    </div>
    <div class="mt-4 pt-3">
        <button class="custom_btn" (click)="openModal()">Get in Touch</button>
    </div>
</div>


<!-- <hr class="mt-0 mb-5"> -->

<!-- <app-faq [heading]="'Web Analytics with AttributeX (FAQs)'" [accordionData]="faqData"></app-faq> -->