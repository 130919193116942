<mat-sidenav-container class="example-container">
    <mat-sidenav-content>
        <div class="bg-white p-3">
            <div class="d-flex justify-content-between align-items-center">
                <a class="navbar-brand" routerLink="/">
                    <img src="assets/images/Logo.webp" routerLink="/" height="25px" alt="Logo">
                </a>

                <img src="assets/images/align_left.svg" *ngIf="!isSidenavOpen" (click)="toggleSidenav()" height="15px"
                    alt="align_left">
                <mat-icon *ngIf="isSidenavOpen" (click)="toggleSidenav()" class="pointer">close</mat-icon>
            </div>
        </div>
    </mat-sidenav-content>
    <mat-sidenav #sidenav mode="over" position="end" (opened)="onSidenavOpen()" (closed)="onSidenavClose()">
        <nav>
            <div class="navbar_section">
                <section class="accordion" *ngFor="let section of navbarData">
                    <ng-container *ngIf="section.items && section.items.length; else noChildSection">
                        <!-- Show checkbox only if there are child items -->
                        <input type="checkbox" [checked]="section.isExpanded" (change)="toggleExpand(section, true)"
                            id="{{section.label}}">
                        <div class="handle">
                            <label for="{{section.label}}">{{section.label}}</label>
                        </div>
                        <div class="content">
                            <ul *ngIf="section.items">
                                <li *ngFor="let item of section.items">
                                    <ng-container *ngIf="item.items && item.items.length; else noChildItem">
                                        <section class="accordion">
                                            <input type="checkbox" [checked]="item.isExpanded"
                                                (change)="toggleExpand(item, false)" id="{{item.label}}">
                                            <div class="handle">
                                                <label for="{{item.label}}">{{item.label}}</label>
                                            </div>
                                            <div class="content">
                                                <ul>
                                                    <li *ngFor="let subItem of item.items">
                                                        <a *ngIf="subItem.route"
                                                            (click)="handleLinkClick(subItem.route)">{{ subItem.label
                                                            }}</a>
                                                        <ng-container *ngIf="!subItem.route">{{ subItem.label
                                                            }}</ng-container>
                                                    </li>
                                                </ul>
                                            </div>
                                        </section>
                                    </ng-container>
                                    <ng-template #noChildItem>
                                        <a *ngIf="item.route" (click)="handleLinkClick(item.route)">{{ item.label }}</a>
                                        <a *ngIf="!item.route">{{ item.label }}</a>
                                        <!-- <ng-container *ngIf="!item.route">{{ item.label }}</ng-container> -->
                                    </ng-template>
                                </li>
                            </ul>
                        </div>
                    </ng-container>
                    <ng-template #noChildSection>
                        <a *ngIf="section.route" (click)="handleLinkClick(section.route)">{{ section.label }}</a>
                        <ng-container *ngIf="!section.route">{{ section.label }}</ng-container>
                    </ng-template>
                </section>
            </div>
            <ul class="navbar-nav d-inline-block d-lg-none">
                <li class="nav-item">
                    <a class="nav-link fw-bold contact_us_btn" (click)="openModal()">Contact Us</a>
                </li>
            </ul>
        </nav>
    </mat-sidenav>
</mat-sidenav-container>