<div class="workiva_section">
    <!-- <div class="banner1">
        <div class="text-section">
            <div class="text1">“Automate financial reporting with ease”</div>
            <div class="text2">“Enhances workflow efficiency through process connectivity”</div>
            <div class="text3">
                Automate time-consuming financial reporting tasks such as verifying numbers and last-minute
                analysis updates
                before board meetings. Attain the speed and adaptability necessary to comprehend the reasons
                behind your
                financial data and ensure executives stay well-informed
            </div>
        </div>
    </div> -->
    <app-slider></app-slider>
    <app-services-cards [services]="services" class="section2 d-flex justify-content-center"></app-services-cards>
    <app-services-tiles [anaplanServices]="workivaServices"></app-services-tiles>

    <!-- <div class="second-section section2 d-flex align-items-center">
        <div class="container">
            <div class="row m-0">
                <div class="col-md-3 pb-3 pb-md-0">
                    <div class="custom_box">
                        <div class="custom_img">
                            <img src="assets/images/services_img1.webp" alt="Bridge Data" width="100%">
                        </div>
                        <div class="custom_heading">Consulting</div>
                        <div class="description"
                            title="Offering expert advisory services in strategic planning and process optimization, leveraging advanced data analytics, cloud solutions, and financial planning platforms to enhance decision-making and operational efficiency.">
                            Offering expert advisory services
                            in strategic planning and process
                            optimization, leveraging advanced data analytics, cloud solutions, and financial
                            planning
                            platforms to enhance decision-making and operational efficiency.
                        </div>
                    </div>
                </div>
                <div class="col-md-3 pb-3 pb-md-0">
                    <div class="custom_box">
                        <div class="custom_img">
                            <img src="assets/images/services_img2.webp" alt="Stream line" width="100%">
                        </div>
                        <div class="custom_heading">Technology</div>
                        <div class="description"
                            title="Specializing in technology services focused on business intelligence, cloud computing, and financial management systems to empower digital transformation and elevate business processes.">
                            Specializing in technology services focused on business intelligence, cloud
                            computing, and financial management systems to empower digital transformation and
                            elevate
                            business processes.
                        </div>
                    </div>
                </div>
                <div class="col-md-3 pb-3 pb-md-0">
                    <div class="custom_box">
                        <div class="custom_img">
                            <img src="assets/images/services_img3.webp" alt="Make decisions" width="100%">
                        </div>
                        <div class="custom_heading">Managed Services</div>
                        <div class="description"
                            title="Providing comprehensive managed services to ensure optimal performance and seamless operation of your cloud infrastructure and enterprise software systems, driving efficiency and stability in business operations.">
                            Providing comprehensive managed services to
                            ensure optimal performance and
                            seamless operation of your cloud infrastructure and enterprise software systems, driving
                            efficiency and stability in business operations.
                        </div>
                    </div>
                </div>
                <div class="col-md-3 pb-3 pb-md-0">
                    <div class="custom_box">
                        <div class="custom_img">
                            <img src="assets/images/services_img4.webp" alt="Make decisions" width="100%">
                        </div>
                        <div class="custom_heading">Staffing</div>
                        <div class="description"
                            title="Delivering tailored staffing solutions with an emphasis on professionals proficient in cutting-edge data analysis, cloud technologies, and enterprise resource planning, meeting the dynamic needs of your projects and organizational goals.">
                            Delivering tailored staffing
                            solutions with an emphasis on professionals
                            proficient in cutting-edge data analysis, cloud technologies, and enterprise resource
                            planning,
                            meeting the dynamic needs of your projects and organizational goals.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->

    <!-- <div class="third-section section3">
        <div class="container px-4">
            <div class="heading">Our Workiva Services</div>
            <div class="description">Transforming enterprise planning and performance with bespoke Anaplan solutions,
                tailored
                to streamline your business processes and enhance decision-making capabilities. </div>
            <div class="row m-0">
                <div class="col">
                    <figure>
                        <img src="assets/images/implementation.webp" alt="Implementation and Integration">
                        <figcaption class="planning_text">Implementation and Integration</figcaption>
                    </figure>
                </div>
                <div class="col">
                    <figure>
                        <img src="assets/images/modal-building.webp" alt="Financial Analysis">
                        <figcaption class="planning_text">Model Building and Optimization </figcaption>
                    </figure>
                </div>
                <div class="col">
                    <figure>
                        <img src="assets/images/financial_analysis.webp" alt="Financial Analysis">
                        <figcaption class="planning_text">Financial Planning and Analysis (FP&A)</figcaption>
                    </figure>
                </div>
                <div class="col">
                    <figure>
                        <img src="assets/images/sales_performanace.webp" alt="Financial Analysis">
                        <figcaption class="planning_text">Sales Performance Management (SPM)</figcaption>
                    </figure>
                </div>
            </div>
        </div>
    </div> -->

    <div class="second-section sixth-section section4" *ngIf="isData">
        <div class="container">
            <div class="heading mb-50">Blogs</div>
            <div class="row_cards workiva_cards">
                <ng-container *ngFor="let data of getData; index as index">
                    <div class="custom_box mx-md-0">
                        <div class="custom_img">
                            <img [src]="data?.main_image" width="100%" [alt]="'blog' + (index + 1)">
                        </div>
                        <div class="blog_heading_section">
                            <div class="title" [title]="data?.title">{{data?.title}}</div>
                        </div>
                        <div class="description" [title]="data?.short_description">{{data?.short_description}}</div>

                        <div class="article_link">
                            <div class="text-primary pointer text-sm text-end d-flex align-items-center gap-1 justify-content-end"
                                (click)="blogDetails(data?.slug)">
                                Open Article <span class="material-symbols-outlined">east</span>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>


    <!-- <div class="fourth-section seventh-section faq_section">
        <div class="container">
            <div class="row m-0">
                <div class="col-md-6">
                    <div class="content_section">
                        <div class="highlight_text">Frequently asked Questions</div>
                        <div>Can’t find an answer?</div>

                        <div class="btn_section">
                            <button class="demo_btn">Contact us for more information</button>
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="qtn_container">
                        <div class="accordion" id="accordionExample">
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingOne">
                                    <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        Are you seeking a solution to streamline the end-to-end reporting process, from
                                        data
                                        collection
                                        to
                                        distribution?
                                    </button>
                                </h2>
                                <div id="collapseOne" class="accordion-collapse collapse show"
                                    aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        Workiva’s automation and data consolidation capabilities can significantly
                                        reduce
                                        the time spent on manual data entry and minimize the risk of errors in your
                                        reports.
                                    </div>
                                </div>
                            </div>

                            <hr>

                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingTwo">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        Are you concerned about data security and compliance with ever-changing
                                        regulations?
                                    </button>
                                </h2>
                                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                                    data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        Quisque sapien augue, ornare id leo a, tristique elementum justo. Praesent non
                                        nulla
                                        sagittis, sollicitudin justo id, varius erat. Nunc sed pharetra nisl. Cras et
                                        suscipit
                                        felis, in lacinia sapien. Integer venenatis sagittis massa, eu eleifend nibh
                                        venenatis
                                        in. Pellentesque a aliquet urna. Curabitur tortor metus, ultrices sed mi at,
                                        sagittis
                                        imperdiet turpis. Suspendisse nec luctus nunc. Fusce in arcu quis lacus mollis
                                        ultrices.
                                    </div>
                                </div>
                            </div>

                            <hr>

                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingThree">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseThree" aria-expanded="false"
                                        aria-controls="collapseThree">
                                        Is real-time collaboration among your team members and stakeholders a challenge?
                                    </button>
                                </h2>
                                <div id="collapseThree" class="accordion-collapse collapse"
                                    aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        Praesent nec ipsum scelerisque dui condimentum pellentesque eu at lectus.
                                        Vivamus
                                        purus
                                        purus, bibendum in vestibulum ac, pharetra sit amet sapien. Nunc luctus, orci
                                        vel
                                        luctus
                                        cursus, nibh nisl ullamcorper ipsum, eu malesuada arcu augue id nisi. In auctor
                                        mi
                                        ac
                                        ante tincidunt tincidunt.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div> -->
</div>