import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, Input, PLATFORM_ID } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { Router } from '@angular/router';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgbCarouselModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-slider',
  standalone: true,
  imports: [CommonModule, NgxSkeletonLoaderModule, NgbCarouselModule, NgbModule],
  templateUrl: './slider.component.html',
  styleUrl: './slider.component.scss'
})
export class SliderComponent {
  @Input() slides: any;
  currentRoute: any;
  getData: any;
  loading: boolean = true;

  styles = {
    'width.%': 100,
    'height.%': 100,
    'border-radius': '0'
  }
  styles2 = {
    'width.%': 100,
    'height.px': 200,
    'border-radius': '0'
  }

  constructor(
    private _router: Router,
    private apiService: ApiService,
    @Inject(PLATFORM_ID) private platformId: any,
    private _changeDetectorRef: ChangeDetectorRef,
  ) {

  }
  ngOnInit() {
    const routeWithoutFirstCharacter = this._router.url;
    this.currentRoute = routeWithoutFirstCharacter.split('/').pop();

    if (this.currentRoute == '') {
      this.currentRoute = 'home'
    } else {
      this.currentRoute = this.currentRoute;
    }
    this.loadBannerData();

  }



  loadBannerData() {
    this.apiService.get<any>(`/home_page_banner/list?page_route=${this.currentRoute}`).subscribe((response) => {
      if (response) {
        this.loading = false;
        this.getData = response?.data?.data;
        this._changeDetectorRef.detectChanges();
      }
      (error: any) => {
        this.loading = false;
      }
    });
  }
}
