<div class="position-relative">
    <img src="assets/images/odoo/manufaturing.webp" width="100%" alt="manufaturing">
    <button class="learn_more_btn" (click)="odooForm()">Learn More</button>
</div>

<div class="text-center second_section">
    <h1 class="heading">Streamline Your Manufacturing Operations with Odoo Manufacturing</h1>
    <div class="description description_2">AttributeX, a trusted Odoo Partner, is here to help you transform your
        manufacturing business with Odoo Manufacturing, a comprehensive suite designed to optimize every aspect of your
        production processes. Whether you're a small-scale producer or managing a complex industrial setup, Odoo’s
        manufacturing module offers powerful features to streamline workflows, improve efficiency, and increase
        productivity.
    </div>
</div>

<app-odoo-services [odooPartner]="odooManufacturing"
    [heading]="'Key Features of Odoo Manufacturing'"></app-odoo-services>


<div class="text-center second_section">
    <h2 class="heading mb-0">Why Choose AttributeX for Odoo Manufacturing?</h2>
    <div class="description ">At AttributeX, we don’t just implement Odoo; we customize and optimize it to fit your
        business perfectly. Our team of experts works closely with you to understand your unique manufacturing processes
        and tailor Odoo’s features for maximum impact.</div>
    <div class="description sub_description">Ready to take your manufacturing operations to the next level? Contact
        AttributeX today to schedule a free consultation and demo of Odoo Manufacturing.</div>
    <div class="mt-4 pt-3">
        <button class="custom_btn" (click)="odooForm()">Get in Touch</button>
    </div>
</div>

<hr class="mt-0 mb-5">

<app-faq [heading]="'Odoo Manufacturing with AttributeX (FAQs)'" [accordionData]="faqData"></app-faq>