import { isPlatformBrowser } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, PLATFORM_ID, Renderer2 } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CanonicalService } from '../../../services/canonical.service';
import { ContactUsComponent } from '../../contact-us/contact-us.component';
import { OdooServicesComponent } from "../odoo-services/odoo-services.component";
import { FaqComponent } from "../../faq/faq.component";

@Component({
  selector: 'app-integration',
  standalone: true,
  imports: [OdooServicesComponent, FaqComponent],
  templateUrl: './integration.component.html',
  styleUrl: './integration.component.scss'
})
export class IntegrationComponent {

  odooPartner = [
    {
      icon: "assets/images/odoo/integration/Integration.svg",
      service_name: "Deep Expertise in Odoo Integration",
      description: "Our team of certified professionals has extensive experience in integrating Odoo with a wide range of third-party applications, including CRM systems, e-commerce platforms, accounting software, and more. We ensure that your systems communicate effectively, providing you with a unified, streamlined operation."
    },
    {
      icon: "assets/images/odoo/integration/Solution.svg",
      service_name: "Custom Integration Solutions",
      description: "At AttributeX, we understand that every business has unique needs. We develop custom integration solutions that align Odoo with your specific business processes, ensuring data flows smoothly across all your systems and departments."
    },
    {
      icon: "assets/images/odoo/integration/End-to-End.svg",
      service_name: "End-to-End Integration Services",
      description: "From initial consultation and planning to development, testing, and deployment, AttributeX provides comprehensive integration services. We manage the entire process, minimizing disruption to your business while maximizing the benefits of your integrated systems."
    },
    {
      icon: "assets/images/odoo/integration/Efficiency.svg",
      service_name: "Enhanced Operational Efficiency",
      description: "By integrating Odoo with your other software systems, we help you eliminate data silos, reduce manual data entry, and improve overall operational efficiency. This integration allows your teams to access real-time information across all platforms, enabling better decision-making and faster response times."
    },
    {
      icon: "assets/images/odoo/integration/Businesses.svg",
      service_name: "Scalable Solutions for Growing Businesses",
      description: "Whether you're a small business or a large enterprise, our scalable integration solutions grow with you. As your business evolves, we can seamlessly integrate additional tools and platforms with your Odoo system, ensuring continuous operational efficiency."
    }
  ];

  implementationProcess = [
    {
      icon: "assets/images/odoo/integration/Consultation.svg",
      service_name: "Consultation and Strategy Development",
      description: "We start by understanding your business requirements and existing technology stack. Our experts then develop a tailored integration strategy that aligns with your business goals."
    },
    {
      icon: "assets/images/odoo/integration/Development.svg",
      service_name: "Custom Integration Development",
      description: "We build custom connectors and middleware that enable seamless communication between Odoo and your other software systems. Our solutions are designed to handle complex workflows and data synchronization tasks with ease."
    },
    {
      icon: "assets/images/odoo/integration/Testing.svg",
      service_name: "Testing and Quality Assurance",
      description: "Before going live, we rigorously test the integration to ensure it works flawlessly in your environment. Our quality assurance process guarantees that your integrated systems operate smoothly without any data loss or disruption."
    },
    {
      icon: "assets/images/odoo/integration/Support.svg",
      service_name: "Deployment and Support",
      description: "Once the integration is tested and approved, we deploy it in your live environment. Our team provides ongoing support and maintenance to ensure your systems continue to work together seamlessly as your business grows."
    }
  ];


  faqData = [
    {
      title: "What is Odoo Integration, and Why is it Important for My Business?",
      content: "Odoo integration involves connecting your Odoo ERP system with other software applications and tools that your business uses, such as CRM systems, e-commerce platforms, accounting software, and more. This integration is crucial because it allows for seamless data flow between different systems, reducing manual data entry, eliminating data silos, and improving overall operational efficiency."
    },
    {
      title: "How Does AttributeX Approach Odoo Integration?",
      content: "AttributeX follows a comprehensive approach to Odoo integration. We begin with a detailed consultation to understand your business needs and existing technology stack. Based on this, we develop a tailored integration strategy, create custom connectors, and rigorously test the integration before deploying it. Our goal is to ensure a seamless, efficient, and secure integration that meets your specific requirements."
    },
    {
      title: "What Types of Systems Can AttributeX Integrate with Odoo?",
      content: "AttributeX can integrate Odoo with a wide range of systems, including but not limited to CRM platforms (like Salesforce), e-commerce platforms (like Shopify or Magento), accounting software (like QuickBooks or Xero), payment gateways, marketing automation tools, and industry-specific applications. We customize the integration to suit your unique business processes."
    },
    {
      title: "How Long Does the Odoo Integration Process Take?",
      content: "The duration of the Odoo integration process depends on the complexity of the systems involved and the scope of the integration. Simple integrations may take a few weeks, while more complex or large-scale integrations could take several months. During our initial consultation, we’ll provide you with a detailed timeline based on your specific needs."
    },
    {
      title: "What Are the Benefits of Integrating Odoo with My Existing Systems?",
      content: "Integrating Odoo with your existing systems offers several benefits, including improved data accuracy, reduced manual data entry, enhanced operational efficiency, and real-time access to critical business information. Integration also allows for better decision-making, faster response times, and a more streamlined workflow across your entire organization."
    },
    {
      title: "Will Odoo Integration Disrupt My Current Business Operations?",
      content: "At AttributeX, we strive to minimize any disruption to your business during the integration process. Our team carefully plans and executes the integration, often in phases, to ensure that your business operations continue smoothly. We also conduct thorough testing to ensure that the integration works flawlessly before it goes live."
    },
    {
      title: "Can AttributeX Provide Ongoing Support After the Integration is Complete?",
      content: "Yes, AttributeX offers ongoing support and maintenance services after the integration is complete. We provide continuous monitoring, troubleshooting, and updates to ensure that your integrated systems continue to operate efficiently and adapt to any changes in your business environment."
    },
    {
      title: "What If My Business Needs Change After the Integration is Complete?",
      content: "Our integration solutions are designed to be scalable and adaptable. If your business needs change after the integration is complete, AttributeX can assist with modifying or expanding the integration to accommodate new systems, processes, or workflows, ensuring your Odoo system evolves with your business."
    },
    {
      title: "How Much Does Odoo Integration with AttributeX Cost?",
      content: "The cost of Odoo integration depends on several factors, including the complexity of the integration, the number of systems involved, and any custom development required. During our initial consultation, we’ll provide you with a detailed quote that outlines all costs associated with the integration."
    },
    {
      title: "How Do I Get Started with Odoo Integration Through AttributeX?",
      content: "To get started, simply contact us through our website or give us a call to schedule an initial consultation. We’ll discuss your business needs, evaluate your current systems, and develop a tailored integration strategy that helps you achieve your business goals."
    }
  ];


  currentUrl: any;


  constructor(
    private modalService: NgbModal,
    @Inject(PLATFORM_ID) private platformId: any,
    private renderer: Renderer2,
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title,
    private meta: Meta,
    private _changeDetectorRef: ChangeDetectorRef,
    private canonicalService: CanonicalService
  ) { }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo(0, 0);
      this.currentUrl = window.location.href;

      this.route.url.subscribe(urlSegments => {
        this.canonicalService.setCanonicalUrl(this.currentUrl);
      });
    }

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.updateMetaTags();
      }
    });

    this.updateMetaTags();
  }

  updateMetaTags() {
    const routeWithoutFirstCharacter = this.router.url;
    const currentRoute = routeWithoutFirstCharacter.split('/').pop();

    if (currentRoute == 'integration') {
      this.titleService.setTitle('AttributeX: Leading Odoo Integration Services Provider in the USA');
      this.meta.updateTag({ name: 'description', content: "Discover AttributeX, the leading provider of Odoo integration services in the USA. Streamline your business operations with expert customization, seamless implementation, and ongoing support." });
      this.meta.updateTag({ name: 'title', content: 'AttributeX: Leading Odoo Integration Services Provider in the USA' });
      this.meta.updateTag({ name: 'keywords', content: 'odoo integration' });
    }

    // Trigger change detection to update the view
    this._changeDetectorRef.detectChanges();
  }

  openModal() {
    this.modalService.open(ContactUsComponent, {
      windowClass: 'custom-modal-lg',
    });
  }
}
