import { Component } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ApiService } from '../../services/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaSettings, RecaptchaV3Module, ReCaptchaV3Service } from 'ng-recaptcha';
import { CaptchaComponent } from "../captcha/captcha.component";
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-contact-us',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, FormsModule, MatFormFieldModule, MatInputModule, CaptchaComponent, RecaptchaV3Module],
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
  providers: [
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: {
        siteKey: environment.recaptcha.SITE_KEY,
      } as RecaptchaSettings,
    },
  ],
})
export class ContactUsComponent {
  formData: FormGroup;
  currentRoute: any;
  receivedToken: string = '';

  constructor(
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private modalService: NgbModal,
    private router: Router,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {
    const routeWithoutFirstCharacter = this.router.url;
    this.currentRoute = routeWithoutFirstCharacter.split('/').pop();

    if (this.currentRoute.includes('blogs/')) {
      this.currentRoute = 'blogs';
    }

    if (this.currentRoute == '') {
      this.currentRoute = 'home';
    }

    this.formData = this.formBuilder.group({
      full_name: ['', Validators.required],
      email: [
        '',
        [
          Validators.required,
          Validators.pattern(
            '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$'
          ),
        ],
      ],
      phone_number: [
        '',
        [Validators.required, Validators.pattern('^[0-9]{10}$')],
      ],
      source_screen: [this.currentRoute],
      comments: ['', Validators.required],
      company_name: ['', Validators.required],
      'g-recaptcha-response': ['']
    });
  }

  onSubmit() {
    if (this.formData.invalid) {
      Object.keys(this.formData.controls).forEach(control => {
        this.formData.controls[control].markAsTouched();
      });
      Swal.fire('Oops', 'Kindly fill all the fields', 'error');
      return;
    } else {
      this.recaptchaV3Service.execute('importantAction')
      .subscribe((token: string) => {
        this.receivedToken = token;
        this.formData.patchValue({
          'g-recaptcha-response': this.receivedToken
        });

        const serializedForm = this.apiService.serializeForm(this.formData.value);

        this.apiService
          .post<any>('/contact_us_request/create', serializedForm)
          .subscribe((response) => {
            if (response?.data.status) {
              this.modalService.dismissAll();
              Swal.fire('Success', `${response?.data?.message}`, 'success').then(
                () => {
                  this.router.navigateByUrl('/home');
                }
              );
            } else {
              Swal.fire('Error', `${response?.data?.message}`, 'error');
            }
          });
      });
    }
  }

    receiveData(event: any) {
    this.receivedToken = event;
    console.log(this.receivedToken);
  }

  closeModal() {
    this.modalService.dismissAll();
  }
}
