import { CommonModule, isPlatformBrowser } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaSettings, RecaptchaV3Module, ReCaptchaV3Service } from 'ng-recaptcha';
import Swal from 'sweetalert2';
import { ApiService } from '../../../../services/api.service';
import { ActivatedRoute, NavigationEnd, Router, RouterModule } from '@angular/router';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { CaptchaComponent } from '../../../captcha/captcha.component';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { Subject } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { CanonicalService } from '../../../../services/canonical.service';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-thankyou',
  standalone: true,
  imports: [CommonModule, RouterModule, ReactiveFormsModule, FormsModule, MatFormFieldModule, MatInputModule, MatSelectModule, MatOptionModule, CaptchaComponent, RecaptchaV3Module],
  templateUrl: './thankyou.component.html',
  styleUrl: './thankyou.component.scss',
  providers: [
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: {
        siteKey: environment.recaptcha.SITE_KEY,
      } as RecaptchaSettings,
    },
  ],
})
export class ThankyouComponent implements OnInit, OnDestroy {
  currentRoute: any;
  private destroy$ = new Subject<void>();
  currentUrl: any;


  constructor(
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private modalService: NgbModal,
    private router: Router,
    private recaptchaV3Service: ReCaptchaV3Service,
    private route: ActivatedRoute,
    private CanonicalService: CanonicalService,
    @Inject(PLATFORM_ID) private platformId: any,
    private titleService: Title,
    private meta: Meta,
    private _changeDetectorRef: ChangeDetectorRef,
  ) {
    const routeWithoutFirstCharacter = this.router.url;
    this.currentRoute = routeWithoutFirstCharacter.split('/').pop();
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo(0, 0);
      this.currentUrl = window.location.href;

      this.route.url.subscribe(urlSegments => {
        this.CanonicalService.setCanonicalUrl(this.currentUrl);
      });
    }

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.updateMetaTags();
      }
    });

    this.updateMetaTags();
  }

  updateMetaTags() {
    const routeWithoutFirstCharacter = this.router.url;
    const currentRoute = routeWithoutFirstCharacter.split('/').pop();

    if (currentRoute == 'odoo-success') {
      this.titleService.setTitle('Fill the form to Contact Odoo Experts at AttributeX');
      this.meta.updateTag({ name: 'description', content: "Contacts Us or Schedule a video conference +1 (858)-304-8004 Meet an expert To assess your project & get a tailored demo" });
      this.meta.updateTag({ name: 'title', content: 'Fill the form to Contact Odoo Experts at AttributeX' });
      this.meta.updateTag({ name: 'keywords', content: 'odoo success' });
      this.meta.addTag({ name: 'robots', content: 'noindex' });
    }

    // Trigger change detection to update the view
    this._changeDetectorRef.detectChanges();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
