import { ChangeDetectorRef, Component, Inject, PLATFORM_ID } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ContactUsComponent } from '../contact-us/contact-us.component';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { ApiService } from '../../services/api.service';
import { AxiosResponse } from 'axios';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [FooterComponent, RouterModule, CommonModule, ReactiveFormsModule],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
})
export class FooterComponent {
  currentYear: any;
  subsbscribeForm: any;
  formData:any;
  receivedToken: any = 'xyz';
  subscribeData: any;

  constructor(
    private apiService: ApiService,
    @Inject(PLATFORM_ID) private platformId: any,
    private modalService: NgbModal,
    private formBuilder: FormBuilder
  ) { 

  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo(0, 0);
    }
    this.currentYear = new Date().getFullYear();

    this.formData = new FormData();

    this.subsbscribeForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$')]]
    });
  }

  emailSubscribe() {
    if (this.subsbscribeForm.invalid) {
    this.subsbscribeForm.markAllAsTouched();
    return
  } else {
      this.formData.append('email', this.subsbscribeForm.get('email').value);
      this.formData.append('g-recaptcha-response', this.receivedToken);

      this.apiService.post(`blog/subscribe_to_email`, this.formData).subscribe((response: AxiosResponse<unknown>) => {
        if (response) {
          this.subscribeData = response?.data;

          console.log(this.subscribeData);

          if (this.subscribeData.status == true) {
            this.modalService.dismissAll();
            Swal.fire('Success', `${this.subscribeData?.message}`, 'success').then(
              () => {
                this.subsbscribeForm.reset();
                if (isPlatformBrowser(this.platformId)) {
                  window.location.reload();
                }
                // this._router.navigateByUrl('/home');
              }
            );
          } else {
            Swal.fire('Error', `${this.subscribeData?.errors?.comments}`, 'error');
          }
        }
      },
        (error) => {
          // this.loading = false;
        }
      );
    }
  }

  openModal() {
    const modalRef = this.modalService.open(ContactUsComponent, {
      windowClass: 'custom-modal-lg',
    });
    // You can pass data to the modal using modalRef.componentInstance
    // For example:
    // modalRef.componentInstance.data = yourData;
  }

  closeModal() {
    this.modalService.dismissAll();
  }
}
