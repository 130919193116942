<div class="third-section" [class.section3]="currentRoute == 'workiva'">
    <div class="container px-4">
        <div class="heading pointer" [routerLink]="anaplanServices?.route">{{anaplanServices.heading}}</div>
        <div class="description">{{anaplanServices.description}}</div>
        <div class="row m-0">
            <div class="col" *ngFor="let service of anaplanServices?.servicesData">
                <figure>
                    <img [src]="service?.imageUrl" [alt]="service?.title">
                    <figcaption class="planning_text">{{ service?.title }}</figcaption>
                </figure>
            </div>
        </div>
    </div>
</div>