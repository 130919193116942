<div class="fourth-section seventh-section mb-5">
    <div class="container">
        <div class="row m-0">
            <div class="col-md-12">
                <div class="content_section">
                    <div class="highlight_text">{{heading}}</div>
                </div>
            </div>

            <div class="col-md-12 pb-5">
                <div class="qtn_container">
                    <div class="accordion" id="faqData">
                        <div *ngFor="let question of accordionData; let i = index" class="accordion-item">
                            <div class="accordion-header" [id]="'heading' + i">
                                <h3 role="button" class="accordion-button" data-bs-toggle="collapse"
                                    [attr.data-bs-target]="'#collapse' + i" aria-expanded="true"
                                    [attr.aria-controls]="'collapse' + i">
                                    {{ question.title }}
                                </h3>
                            </div>
                            <div [id]="'collapse' + i" class="accordion-collapse collapse" [class.show]="i === 0"
                                [attr.aria-labelledby]="'heading' + i" data-bs-parent="#faqData">
                                <div class="accordion-body">
                                    {{ question.content }}
                                </div>
                            </div>
                            <hr *ngIf="i !== accordionData.length - 1"> <!-- Add hr except for last item -->
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>
</div>