<div class="container">

    <!-- <div class="row"> -->
    <!-- <div class="col-lg-3">
            <aside class="blog__aside my-4">
                <div class="blog__author">
                    <p class="my-4 blog_title text-capitalize d-lg-none" *ngIf="!loading">{{this.getData?.data?.title}}
                    </p>
                    <div class="blog__social mt-4 d-lg-none">
                        <ul class="blog__social-share list-unstyled align-items-center gap-2">
                            <h6>Share</h6>
                            <li><a href="#" target="_blank"><i class="fa fa-twitter"></i><span class="hidden">Share on
                                        Twitter</span></a></li>
                            <li><a href="#" target="_blank"><i class="fa fa-facebook"></i><span class="hidden">Share on
                                        Facebook</span></a></li>
                            <li><a href="#" target="_blank"><i class="fa fa-linkedin"></i><span class="hidden">Share on
                                        LinkedIn</span></a></li>
                            <button class="blog_subscribe_btn mt-0" (click)="scrollToId()"><a>Subscribe</a></button>
                        </ul>
                        <hr>
                    </div>
                    <section class="blog__author--details">
                        <img alt="AttributeX Logo" class="my-4" src="assets/images/Logo.webp">
                        <div class="blog__author-biography">
                            <h4 *ngIf="!loading">{{blogName}}</h4>
                            <ngx-skeleton-loader *ngIf="loading" count="1" appearance="line" [theme]="{
                                'width.%': 100,
                                'height.px': 30,
                                'margin-top': '2rem',
                                'border-radius': '6px'
                              }" />
                        </div>
                    </section>
                </div>
                <div class="blog__social mt-4 d-none d-lg-block">
                    <h6>Share</h6>
                    <ul class="blog__social-share list-unstyled">
                        <li><a (click)="socialShare('twitter')" target="_blank"><i class="fa fa-twitter"></i></a></li>
                        <li><a (click)="socialShare('facebook ')" target="_blank"><i class="fa fa-facebook"></i></a>
                        </li>
                        <li><a (click)="socialShare('linkedin')" target="_blank"><i class="fa fa-linkedin"></i></a></li>
                    </ul>
                    <button class="blog_subscribe_btn" (click)="scrollToId()"><a>Subscribe</a></button>
                </div>
            </aside>
        </div> -->
    <!-- <div class="col-lg-9"> -->
    <div>
        <div>
            <p class="my-4 blog_title text-capitalize" *ngIf="!loading">
                {{this.getData?.data?.title}}</p>
            <ngx-skeleton-loader *ngIf="loading" count="1" appearance="line" [theme]="{
                    'width.%': 100,
                    'height.px': 30,
                    'margin-top': '20px',
                    'border-radius': '6px'
                  }" />
            <img [src]="this.getData?.data?.main_image" class="main_image" *ngIf="!loading">
            <ngx-skeleton-loader *ngIf="loading" count="1" appearance="circle" [theme]="{
                    'width.%': 100,
                    'height.px': 500,
                    'border-radius': '10px'
                    }" />
        </div>
        <div class="text_section">
            <ngx-skeleton-loader *ngIf="loading" count="6" appearance="line" [theme]="{
                    'width.%': 100,
                    'height.px': 10,
                    'border-radius': '0px'
                  }" />
            <div class="blog_content" *ngIf="!loading" [innerHTML]="this.getData?.data?.content"></div>
        </div>
        <div>
            <div class="row m-0 my-5" *ngIf="loading">
                <div class="col-md-4 pb-4">
                    <ngx-skeleton-loader count="1" appearance="circle" [theme]="styles" />
                </div>
                <div class="col-md-4 pb-4">
                    <ngx-skeleton-loader count="1" appearance="circle" [theme]="styles" />
                </div>
                <div class="col-md-4 pb-4">
                    <ngx-skeleton-loader count="1" appearance="circle" [theme]="styles" />
                </div>
            </div>
            <div class="row m-0 my-5"
                *ngIf="(this.getData?.data?.image1 || this.getData?.data?.image2 || this.getData?.data?.image1) && !loading">
                <div class="col-md-4 pb-4" *ngIf="this.getData?.data?.image1">
                    <img [src]="this.getData?.data?.image1" class="blog_img" alt="blog image 1" *ngIf="!loading"
                        (click)="imgSlider('IMAGE1', this.getData?.data)">
                </div>
                <div class="col-md-4 pb-4" *ngIf="this.getData?.data?.image2">
                    <img [src]="this.getData?.data?.image2" class="blog_img" alt="blog image 2" *ngIf="!loading"
                        (click)="imgSlider('IMAGE2', this.getData?.data)">
                </div>
                <div class="col-md-4" *ngIf="this.getData?.data?.image3">
                    <img [src]="this.getData?.data?.image3" class="blog_img" alt="blog image 3" *ngIf="!loading"
                        (click)="imgSlider('IMAGE3',this.getData?.data)">
                </div>
            </div>
        </div>
    </div>
    <!-- </div> -->
    <!-- </div> -->
</div>

<div class="social_share_section">
    <div class="container">
        <hr>
        <aside class="blog__aside d-flex justify-content-center py-3">
            <div class="blog__social">
                <h6>Share</h6>
                <ul class="blog__social-share list-unstyled">
                    <li><a (click)="socialShare('twitter')" target="_blank"><i class="fa fa-twitter"></i></a></li>
                    <li><a (click)="socialShare('facebook ')" target="_blank"><i class="fa fa-facebook"></i></a>
                    </li>
                    <li><a (click)="socialShare('linkedin')" target="_blank"><i class="fa fa-linkedin"></i></a></li>
                </ul>
                <!-- <button class="blog_subscribe_btn" (click)="scrollToId()"><a>Subscribe</a></button> -->
            </div>
        </aside>
    </div>
</div>

<div class="subscribe-section" #subscribeForm>
    <div class="row m-0 h-100">
        <div class="col-md-8">
            <div class="d-flex align-items-center justify-content-center h-100">
                <div class="content-section">
                    <div class="heading_text mb-4">Subscribe to our Blogs and Newsletters</div>
                    <div class="description mb-4">
                        Stay informed with the latest updates and views from AttributeX sent directly to your inbox.
                    </div>

                    <form class="email_section" [formGroup]="subsbscribeForm" (ngSubmit)="emailSubscribe()">
                        <input type="text" #InputField class="form-control emailInput" formControlName="email"
                            [ngClass]="{ 'is-invalid': subsbscribeForm.get('email')?.invalid && subsbscribeForm.get('email')?.touched }"
                            placeholder="Company Email">
                        <div *ngIf="subsbscribeForm.get('email').invalid && subsbscribeForm.get('email').touched">
                            <small *ngIf="subsbscribeForm.get('email').errors?.required" class="text-danger">
                                Email is required.
                            </small>
                            <small *ngIf="subsbscribeForm.get('email').errors?.pattern" class="text-danger">
                                Please enter a valid email address.
                            </small>
                        </div>
                        <!-- <div class="mt-4">
                            <app-captcha (dataEvent)="receiveData($event)"></app-captcha>
                        </div> -->
                        <button class="subsbcribe_btn" type="submit" name="submit">Subscribe</button>
                    </form>
                </div>
            </div>
        </div>

        <div class="col-md-4 h-100 d-none d-md-block pe-md-0">
            <div class="layout-container__background h-100 d-flex justify-content-end">
                <img src="assets/images/subscribe1.png" alt="subscribe">
            </div>
        </div>
    </div>
</div>