<nav class="navbar navbar-expand-lg bg-white d-none d-lg-block p-0">
  <div class="container-fluid">
    <div class="left-section">
      <div class="d-flex justify-content-between align-items-center">
        <a class="navbar-brand" routerLink="/">
          <img src="assets/images/Logo.webp" alt="Logo">
        </a>

        <div>
          <ng-container *ngFor="let menuItem of menuItems">
            <img class="pointer d-lg-none" *ngIf="menuItem?.logo" height="40px" [src]="menuItem?.logo"
              [routerLink]="menuItem?.route" />
          </ng-container>
        </div>
      </div>
      <div>
        <div class="collapse navbar-collapse" #collapsibleNavbar id="collapsibleNavbar">
          <div class="navbar_section">
            <ul class="links">
              <ng-container *ngFor="let menuItem of menuItems">
                <li [routerLink]="menuItem?.route">
                  <a class="pointer" *ngIf="menuItem?.label">{{ menuItem?.label }}</a>
                  <img class="pointer d-none d-lg-block" *ngIf="menuItem?.logo" height="40px" [src]="menuItem?.logo" />
                  <i *ngIf="menuItem?.more === true"
                    class="material-symbols-outlined htmlcss-arrow arrow pointer">keyboard_arrow_down</i>
                  <ul class="htmlCss-sub-menu sub-menu" *ngIf="menuItem?.children && menuItem?.children?.length > 0">
                    <!-- *ngIf="menuItem?.children && menuItem?.children?.length > 0"> -->
                    <ng-container *ngFor="let childItem of menuItem?.children">
                      <li [routerLink]="childItem?.route" *ngIf="!childItem?.more || childItem?.more === false">
                        <a>{{ childItem?.label }}</a>
                      </li>
                      <li class="more" *ngIf="childItem?.more === true">
                        <span class="w-100" [routerLink]="childItem?.route">
                          <a class="d-flex">{{
                            childItem?.label }}
                            <i class="material-symbols-outlined arrow more-arrow">keyboard_arrow_right</i>
                          </a>
                        </span>
                        <ul class="more-sub-menu sub-menu">
                          <li *ngFor="let subItem of childItem?.subItems" (click)="subItemsNavigate(subItem?.route)">
                            <a>{{ subItem?.label }}</a>
                          </li>
                        </ul>
                      </li>
                    </ng-container>
                  </ul>
                </li>
              </ng-container>
            </ul>
          </div>
          <ul class="navbar-nav d-inline-block d-md-none">
            <li class="nav-item">
              <a class="nav-link fw-bold contact_us_btn" (click)="openModal()">Contact Us</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="right-section d-none d-md-block">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link btn btn-primary contact_us_btn" (click)="openModal()">Contact Us</a>
        </li>
      </ul>
    </div>
  </div>
</nav>