<div class="second-section" [class.workiva-services-section]="currentRoute == 'workiva'">
    <div class="container">
        <div class="heading mb-50">{{services.heading}}</div>
        <div class="row m-0">
            <div class="col-md-6 col-xl-3 pb-3 pb-xl-0" *ngFor="let service of services?.cards">
                <div class="custom_box" [routerLink]="service?.route">
                    <div class="custom_img">
                        <img [src]="service?.imageUrl" alt="{{ service?.title }}" width="100%">
                    </div>
                    <div class="custom_heading">{{ service?.title }}</div>
                    <div class="description" [title]="service?.description">
                        {{ service?.description }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>