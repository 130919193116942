import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationEnd, Router, RouterModule, RouterOutlet } from '@angular/router';
import { NavbarComponent } from "./components/navbar/navbar.component";
import { HomeComponent } from "./components/home/home.component";
import { FooterComponent } from "./components/footer/footer.component";
import { WorkivaComponent } from './components/workiva/workiva.component';
import { MegaMenuComponent } from "./components/mega-menu/mega-menu.component";
import { environment } from '../environments/environment';
import { CaptchaComponent } from './components/captcha/captcha.component';
import { SitemapService } from './services/sitemap.service';
import { MetaService } from './services/meta.service';
import { SideNavComponent } from "./components/navbar/side-nav/side-nav.component";
declare global {
  interface Window {
    dataLayer: any;
  }
}
@Component({
  selector: 'app-root',
  standalone: true,
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  imports: [CommonModule, RouterModule, RouterOutlet, NavbarComponent, FooterComponent, SideNavComponent]
})

export class AppComponent {
  currentRoute: any;
  // dataLayer: any = [];

  // constructor(private sitemapService: SitemapService) {
  constructor(
    private metaService: MetaService,
    private router: Router,
  ) {
    setTimeout(() => {
      const routeWithoutFirstCharacter = this.router.url;
      this.currentRoute = routeWithoutFirstCharacter.split('/').pop();
    }, 100);

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = this.router.url.split('/').pop();
      }
    });
  }

  // sitemap(){
  //     const sitemapXml = this.sitemapService.generateSitemapXml();
  //     const blob = new Blob([sitemapXml], { type: 'application/xml' });
  //     const url = window.URL.createObjectURL(blob);

  //     const a = document.createElement('a');
  //     a.href = url;
  //     a.download = 'sitemap.xml';
  //     document.body.appendChild(a);
  //     a.click();
  //     window.URL.revokeObjectURL(url); 

  ngOnInit() {
    if (!environment.production) {
      this.metaService.setNoIndex();
    }
  }
}
