import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { SliderComponent } from '../../slider/slider.component';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ContactUsComponent } from '../../contact-us/contact-us.component';

@Component({
  selector: 'app-cloud',
  standalone: true,
  imports: [SliderComponent,CommonModule],
  templateUrl: './cloud.component.html',
  styleUrl: './cloud.component.scss'
})
export class CloudComponent {
data:any = [
  {
    icon: "assets/images/cloud/cloud solutions.svg",
    service_name: "Our Cloud Solutions and Strategy Services",
    description: "In an era where agility and scalability are paramount, cloud computing stands at the forefront of digital transformation. At AttributeX, we specialize in providing comprehensive cloud solutions and strategic guidance across leading cloud platforms, including AWS, Oracle Cloud Infrastructure, Microsoft Azure, and Google Cloud Platform. Our mission is to help your business harness the power of the cloud to drive innovation, efficiency, and growth."
  },
  {
    icon: "assets/images/cloud/cloud architecture.svg",
    service_name: "Cloud Architecture Design",
    description: "Designing a robust and scalable cloud architecture is crucial for long-term success. Our team creates tailored cloud architectures that align with your business goals and ensure optimal performance and security."
  },
  {
    icon: "assets/images/cloud/multi cloud.svg",
    service_name: "Multi-Cloud and Hybrid Solutions",
    description: "Whether you’re leveraging multiple cloud providers or integrating on-premises systems with cloud environments, we develop multi-cloud and hybrid solutions that provide flexibility, resilience, and cost-effectiveness."
  },
  {
    icon: "assets/images/cloud/security.svg",
    service_name: "Security and Compliance",
    description: "Security is at the core of our cloud strategy. We implement stringent security measures and compliance protocols to protect your data and ensure adherence to industry standards and regulations."
  },
  {
    icon: "assets/images/cloud/cost.svg",
    service_name: "Cost Optimization",
    description: "Optimize your cloud spending with our cost management services. We help you identify and eliminate inefficiencies, ensuring you get the most value from your cloud investment."
  },
  {
    icon: "assets/images/cloud/cloud mgmt.svg",
    service_name: "Cloud Management and Support",
    description: "Our managed services provide ongoing support and maintenance for your cloud environment. From performance monitoring to troubleshooting and updates, we ensure your cloud infrastructure operates seamlessly."
  },
  {
    icon: "assets/images/cloud/disaster.svg",
    service_name: "Disaster Recovery and Business Continuity",
    description: "Ensure your business remains resilient with our disaster recovery and business continuity solutions. We design and implement strategies that minimize downtime and protect your critical data."
  },
]

cloudSolutionsData:any = [
  {
    icon: "assets/images/cloud/tailored.svg",
    service_name: "Tailored Strategies for Your Business Needs",
    description: "We understand that every business is unique. Our cloud strategies are customized to meet your specific requirements, ensuring a perfect fit for your operations and objectives."
  },
  {
    icon: "assets/images/cloud/proven expertise.svg",
    service_name: "Proven Expertise Across Leading Platforms",
    description: "With extensive experience in AWS, Oracle Cloud Infrastructure, Microsoft Azure, and Google Cloud Platform, our team brings unparalleled expertise to your cloud initiatives"
  },
  {
    icon: "assets/images/cloud/commitment.svg",
    service_name: "Commitment to Innovation and Excellence",
    description: "We are dedicated to staying ahead of the curve, continuously exploring new cloud technologies and methodologies to deliver cutting-edge solutions for our clients."
  }
]

powerOfCloud:any = [
  {
    icon: "assets/images/cloud/transform.svg",
    service_name: "Transform Your Operations",
    description: "Experience the agility, scalability, and efficiency of a cloud-first approach. Our solutions empower you to innovate faster and operate smarter."
  },
  {
    icon: "assets/images/cloud/drive business.svg",
    service_name: "Drive Business Growth",
    description: "Leverage the cloud to unlock new opportunities for growth and expansion. We help you create a cloud strategy that drives your business forward."
  },
  {
    icon: "assets/images/cloud/ensure long term.svg",
    service_name: "Ensure Long-Term Success",
    description: "With our strategic guidance and ongoing support, your cloud journey is set for long-term success. Partner with us to navigate the complexities of the cloud with confidence."
  }
]


dynamicContent: any;

cloudContent: any = {
  heading: 'Ready to Elevate Your Cloud Strategy?',
  description: 'Contact us today to learn how our cloud solutions and strategy services can transform your business.',

}

constructor(
  private modalService: NgbModal,
  @Inject(PLATFORM_ID) private platformId: any,
) { }

ngOnInit() {
  if (isPlatformBrowser(this.platformId)) {
    window.scrollTo(0, 0);
  }
}

openModal() {
  this.modalService.open(ContactUsComponent, {
    windowClass: 'custom-modal-lg',
  });
}
}
