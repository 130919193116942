<div class="position-relative">
    <img src="assets/images/odoo/integration/integration.webp" width="100%" alt="integration">
    <button class="learn_more_btn" (click)="openModal()">Learn More</button>
</div>

<div class="text-center second_section">
    <h1 class="heading">AttributeX: Leading Odoo Integration Experts in the USA</h1>
    <div class="description description_2">In today's fast-paced business environment, seamless integration of your
        software systems is key to maintaining efficiency and staying ahead of the competition. AttributeX is a leading
        provider of Odoo integration services in the USA, offering expertise that ensures your Odoo ERP system works
        harmoniously with your existing tools and technologies.
    </div>
</div>

<app-odoo-services [odooPartner]="odooPartner"
    [heading]="'Why Choose AttributeX for Odoo Integration?'"></app-odoo-services>


<div class="text-center second_section">
    <h2 class="heading mb-0">Unlock the Full Potential of Odoo with AttributeX</h2>
    <div class="description ">At AttributeX, we believe that integration is the key to unlocking the full potential of
        your Odoo ERP system. Our expert team is dedicated to helping you achieve a fully connected, efficient, and
        scalable business operation.
    </div>
    <div class="description sub_description">Ready to integrate Odoo with your existing systems? Contact AttributeX
        today to learn more about our integration services and how we can help you create a unified, efficient business
        environment.</div>
    <div class="mt-4 pt-3">
        <button class="custom_btn" (click)="openModal()">Get in Touch</button>
    </div>
</div>

<app-odoo-services [odooPartner]="implementationProcess"
    [heading]="'Our Odoo Integration Services'"></app-odoo-services>

<hr class="mt-0 mb-5">

<app-faq [heading]="'Odoo Integration with AttributeX (FAQs)'" [accordionData]="faqData"></app-faq>