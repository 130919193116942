<div class="slider_section blog_slider position-relative">
    <button type="button" class="btn-close" (click)="closeModal()"></button>
    <ngb-carousel id="robotcarousel" class="h-100" *ngIf="imageList && imageList.length > 0"
        [showNavigationArrows]="true" [showNavigationIndicators]="false">
        <ng-template ngFor let-image [ngForOf]="imageList" let-i="index">
            <ng-template ngbSlide class="h-100">
                <img [src]="image" class="image_section" alt="Slide {{ i + 1 }}">
            </ng-template>
        </ng-template>
    </ngb-carousel>
</div>