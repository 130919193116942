<div class="odoo_form_header d-flex align-items-center justify-content-between">
    <img src="assets/images/odoo/x_logo.webp" class="x_logo" alt="x logo">

    <div class="text-section">
        <div class="header_text">Empowering Businesses with Tailored
            <span class="highlighted_text">Technology Solutions</span>
        </div>
        <div>
            <img src="assets/images/Logo.webp" width="124px" alt="attributex logo">
        </div>
    </div>
</div>


<div class="success_form text-center my-5">
    <img src="assets/images/odoo/success_img.webp" height="230px" alt="success image">
    <div class="success_text mt-4">Success! Your Form Has Been Submitted</div>
    <div class="thanks_text">Thank you for providing the information. We'll get back to you shortly.</div>
    <button class="btn btn-contact-bg" routerLink="/">Back to Home</button>
</div>