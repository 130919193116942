import { ChangeDetectorRef, Component, Inject, PLATFORM_ID } from '@angular/core';
import { OdooServicesComponent } from "../odoo/odoo-services/odoo-services.component";
import { FaqComponent } from "../faq/faq.component";
import { ContactUsComponent } from '../contact-us/contact-us.component';
import { isPlatformBrowser } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CanonicalService } from '../../services/canonical.service';

@Component({
  selector: 'app-web-analytics',
  standalone: true,
  imports: [OdooServicesComponent],
  templateUrl: './web-analytics.component.html',
  styleUrl: './web-analytics.component.scss'
})
export class WebAnalyticsComponent {
  bgColor = 'white';
  bgCardColor = '#D7E5EA !important';
  odooPartner = [
    {
      icon: "assets/images/web_analytics/Track_Key_Metrics.svg",
      service_name: "Track Key Metrics",
      description: "Monitor traffic, conversions, and engagement in real time."
    },
    {
      icon: "assets/images/web_analytics/Understand_User_Behaviour.svg",
      service_name: "Understand User Behavior",
      description: "Gain insights into how visitors interact with your website."
    },
    {
      icon: "assets/images/web_analytics/Optimize_Marketing_Campaigns.svg",
      service_name: "Optimize Marketing Campaigns",
      description: "Identify top-performing channels and campaigns."
    },
    {
      icon: "assets/images/web_analytics/Improve_UX.svg",
      service_name: "Improve User Experience",
      description: "Detect pain points and enhance navigation for better retention."
    },
    {
      icon: "assets/images/web_analytics/Drive_ROI.svg",
      service_name: "Drive ROI",
      description: "Leverage data to maximize your marketing spend and impact."
    }
  ];

  WhyChooseAttributeX = [
    {
      icon: "assets/images/web_analytics/Expertise.svg",
      service_name: "Expertise",
      description: "Our team is skilled in leading analytics tools and platforms."
    },
    {
      icon: "assets/images/web_analytics/Actionable_Insights.svg",
      service_name: "Actionable Insights",
      description: "We go beyond data collection to deliver meaningful recommendations."
    },
    {
      icon: "assets/images/web_analytics/Scalable_Solutions.svg",
      service_name: "Scalable Solutions",
      description: "Whether you’re a startup or an enterprise, our services scale with your needs."
    },
    {
      icon: "assets/images/web_analytics/Ongoing_Support.svg",
      service_name: "Ongoing Support",
      description: "From setup to ongoing optimization, we’re your analytics partner every step of the way."
    }
  ];

  successStories = [
    {
      title: "Increased Conversion Rates for an E-commerce Brand",
      description: "By implementing advanced e-commerce tracking and funnel analysis, we identified drop-off points and optimized the checkout process, leading to a 25% increase in conversions."
    },
    {
      title: "Enhanced Marketing ROI for a SaaS Company",
      description: "Using Adobe Analytics, we provided detailed customer segmentation that enabled targeted campaigns, resulting in a 40% improvement in marketing ROI."
    },
    {
      title: "Improved User Engagement for a Media Website",
      description: "With heatmaps and user flow analysis, we helped restructure content placement, boosting time-on-site metrics by 30%."
    }
  ];

  WebAnalyticsServices = [
    {
      icon: "assets/images/web_analytics/Google.svg",
      service_name: "Google Analytics Integration and Optimization",
      description: `Harness the full potential of Google Analytics to uncover critical insights: - Setting up and configuring Google Analytics for your business.\n- Advanced tracking for events, goals, and e-commerce performance.\n- Creating customized dashboards and reports.\n- Conducting in-depth analysis to identify trends and opportunities.`
    },
    {
      icon: "assets/images/web_analytics/Adobe.svg",
      service_name: "Adobe Analytics Implementation",
      description: "Take your analytics to the next level with the powerful capabilities of Adobe Analytics:\n- Seamless integration with your existing systems.\n- Advanced segmentation and audience insights.\n- Multi-channel tracking for a holistic view of your customer journey.\n- Real-time data visualization for quick decision-making."
    },
    {
      icon: "assets/images/web_analytics/Custom.svg",
      service_name: "Custom Analytics Solutions",
      description: "Every business is unique, and so are its analytics needs. We provide:\n- Tailored tracking strategies aligned with your goals.\n- Implementation of heatmaps and session recordings for UX improvement.\n- Cross-domain and cross-device tracking for a complete user profile.\n- Integration with other tools like CRM and marketing platforms."
    }
  ];


  currentUrl: any;


  constructor(
    private modalService: NgbModal,
    @Inject(PLATFORM_ID) private platformId: any,
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title,
    private meta: Meta,
    private _changeDetectorRef: ChangeDetectorRef,
    private canonicalService: CanonicalService
  ) { }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo(0, 0);
      this.currentUrl = window.location.href;

      this.route.url.subscribe(urlSegments => {
        this.canonicalService.setCanonicalUrl(this.currentUrl);
      });
    }

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.updateMetaTags();
      }
    });

    this.updateMetaTags();
  }

  updateMetaTags() {
    const routeWithoutFirstCharacter = this.router.url;
    const currentRoute = routeWithoutFirstCharacter.split('/').pop();

    if (currentRoute == 'industries/web-analytics') {
      this.titleService.setTitle('Web Analytics Solutions | Google Analytics & Adobe Analytics | AttributeX');
      this.meta.updateTag({ name: 'description', content: "Unlock actionable insights with Web Analytics Solutions by AttributeX. Leverage Google Analytics, Adobe Analytics, and custom strategies to optimize website performance, improve user engagement, and drive ROI" });
      this.meta.updateTag({ name: 'title', content: 'Web Analytics Solutions | Google Analytics & Adobe Analytics | AttributeX' });
      this.meta.updateTag({ name: 'keywords', content: 'web analytics' });
    }

    // Trigger change detection to update the view
    this._changeDetectorRef.detectChanges();
  }

  openModal() {
    this.modalService.open(ContactUsComponent, {
      windowClass: 'custom-modal-lg',
    });
  }
}
