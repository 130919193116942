import { CommonModule, isPlatformBrowser } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, Inject, PLATFORM_ID, Renderer2, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ContactUsComponent } from '../contact-us/contact-us.component';
import { ActivatedRoute, NavigationEnd, Router, RouterModule } from '@angular/router';
import { OdooServicesComponent } from './odoo-services/odoo-services.component';
import { Meta, Title } from '@angular/platform-browser';
import { CanonicalService } from '../../services/canonical.service';

@Component({
  selector: 'app-odoo',
  standalone: true,
  imports: [CommonModule, RouterModule, OdooServicesComponent],
  templateUrl: './odoo.component.html',
  styleUrl: './odoo.component.scss'
})

export class OdooComponent {
  @ViewChild('videoPlayer') videoPlayer!: ElementRef<HTMLVideoElement>;

  caseStudies = [
    {
      imgSrc: 'assets/images/odoo/case_study_1.webp',
      heading: 'Odoo Helps MPF Consultancy GUM Save 50% Time On HR Tasks',
      content: 'Apps Implemented: Attendances, Surveys, Project, Email Marketing, eLearning, Live Chat, Helpdesk, Employee Contracts, Payroll, Appraisals, Forum, Website, Blogs, Documents, Studio, Accounting, Sales, Invoicing, Discuss, Contacts, Calendar, Dashboards, Employees, Consolidation',
      tags: ['APSC', 'ASIA', 'financial services', 'mid sized organisation', 'odoo.sh', 'services'],
      url: 'https://pstest-attributex.odoo.com/blog/case-studies-2/odoo-helps-mpf-consultancy-gum-save-50-time-on-hr-tasks-2'
    },
    {
      imgSrc: 'assets/images/odoo/case_study_2.webp',
      heading: 'Awarding Excellence: AwardsPlus’ Journey to High-Achievement with Odoo’s Automated ERP Software',
      content: 'Apps implemented: Sales, Employees, Discuss, Calendar, Contacts, CRM, Accounting, Project, Purchase, Helpdesk, Website, Manufacturing, Time Off',
      tags: ['micro-organisation (1-9)', 'north america', 'odoo.sh', 'retail'],
      url: 'https://pstest-attributex.odoo.com/blog/case-studies-2/awarding-excellence-awardsplus-journey-to-high-achievement-with-odoos-automated-erp-software-3'
    },
    {
      imgSrc: 'assets/images/odoo/case_study_3.webp',
      heading: 'Odoo & Itransition Deliver BPM Solutions for Maxmed Healthcare',
      content: 'Main Apps Implemented: Contacts, CRM, Sales, Invoicing, Timesheet, Project, Purchase, Employees, Leaves/Time Off, Recruitment, Helpdesk, Documents, eSign, Payroll (data import from a 3rd-party service)',
      tags: ['health & life sciences', 'north america', 'odoo.sh', 'small organisation (10-49)'],
      url: 'https://pstest-attributex.odoo.com/blog/case-studies-2/odoo-itransition-deliver-bpm-solutions-for-maxmed-healthcare-4'
    }
  ];
  currentUrl: any;



  constructor(
    private modalService: NgbModal,
    @Inject(PLATFORM_ID) private platformId: any,
    private renderer: Renderer2,
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title,
    private meta: Meta,
    private _changeDetectorRef: ChangeDetectorRef,
    private canonicalService: CanonicalService
  ) { }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo(0, 0);
      this.currentUrl = window.location.href;

      this.route.url.subscribe(urlSegments => {
        this.canonicalService.setCanonicalUrl(this.currentUrl);
      });
    }

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.updateMetaTags();
      }
    });

    this.updateMetaTags();
  }

  updateMetaTags() {
    const routeWithoutFirstCharacter = this.router.url;
    const currentRoute = routeWithoutFirstCharacter.split('/').pop();

    if (currentRoute == 'odoo') {
      // this.titleService.setTitle('AttributeX: Go-to Odoo Partner in the USA');
      this.titleService.setTitle('AttributeX: Leading Odoo Partner USA | Odoo Implementation, Development & Support');
      this.meta.updateTag({ name: 'description', content: "Discover AttributeX, a trusted Odoo partner in the USA offering expert Odoo implementation, development, customization, and support. Our certified Odoo consultants provide tailored solutions to streamline your business. Contact us for a demo or consultation!" });
      this.meta.updateTag({ name: 'title', content: 'AttributeX: Leading Odoo Partner USA | Odoo Implementation, Development & Support' });
      this.meta.updateTag({ name: 'keywords', content: 'odoo' });
    }

    // Trigger change detection to update the view
    this._changeDetectorRef.detectChanges();
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const videoElement = this.videoPlayer.nativeElement;
    const rect = videoElement.getBoundingClientRect();
    const isVisible = rect.top >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight);

    if (!isVisible) {
      videoElement.pause();  // Corrected: Use the pause() method instead of setting paused property
    }
  }

  openModal() {
    this.modalService.open(ContactUsComponent, {
      windowClass: 'custom-modal-lg',
    });
  }

  caseStudy(url: any) {
    window.open(url, '_blank');
  }

  odooForm() {
    window.open('/odoo-form', '_blank');
  }
}
