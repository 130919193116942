<div class="odoo_form_header d-flex align-items-center justify-content-between">
    <img src="assets/images/odoo/x_logo.webp" class="x_logo" alt="x logo">

    <div class="text-section">
        <div class="header_text">Empowering Businesses with Tailored
            <span class="highlighted_text">Technology Solutions</span>
        </div>
        <div>
            <img src="assets/images/Logo.webp" width="124px" alt="attributex logo">
        </div>
    </div>
</div>


<div class="contact">
    <form class="form" [formGroup]="formData" (ngSubmit)="onSubmit()">
        <div class="row">
            <div class="form-group col-md-12">
                <label for="name">Name <span class="text-danger">*</span></label>
                <input id="name" type="text" formControlName="name" class="form-control" placeholder="Name"
                    [ngClass]="{ 'is-invalid': formData.get('name')?.invalid && formData.get('name')?.touched }">
                <div *ngIf="formData.get('name')?.invalid && formData.get('name')?.touched">
                    <small *ngIf="formData.get('name')?.errors?.['required']" class="text-danger">
                        Name is required.
                    </small>
                </div>
            </div>
            <div class="form-group col-md-12">
                <label for="email">Email <span class="text-danger">*</span></label>
                <input type="email" id="email" formControlName="email" class="form-control" placeholder="Work Email"
                    [ngClass]="{ 'is-invalid': formData.get('email')?.invalid && formData.get('email')?.touched }">
                <div *ngIf="formData.get('email')?.invalid && formData.get('email')?.touched">
                    <small *ngIf="formData.get('email')?.errors?.['required']" class="text-danger">
                        Work Email is required.
                    </small>
                    <small *ngIf="formData.get('email')?.errors?.['pattern']" class="text-danger">
                        Please enter a valid email address.
                    </small>
                </div>
            </div>
            <div class="form-group col-md-12">
                <label for="phone">Phone Number <span class="text-danger">*</span></label>
                <input type="text" id="phone" formControlName="phone_number" class="form-control" maxlength="10"
                    placeholder="Phone number"
                    [ngClass]="{ 'is-invalid': formData.get('phone_number')?.invalid && formData.get('phone_number')?.touched }">
                <div *ngIf="formData.get('phone_number')?.invalid && formData.get('phone_number')?.touched">
                    <small *ngIf="formData.get('phone_number')?.errors?.['required']" class="text-danger">
                        Phone Number is required.
                    </small>
                    <small *ngIf="formData.get('phone_number')?.errors?.['pattern']" class="text-danger">
                        Please enter a valid 10-digit Phone Number..
                    </small>
                </div>
            </div>

            <div class="form-group col-md-12">
                <label>Industry Type <span class="text-danger">*</span></label>
                <mat-form-field class="w-100">
                    <mat-select placeholder="Select Industry" [formControlName]="'industry_type'">
                        <ng-container *ngFor="let type of getIndustryType">
                            <mat-option [value]="type">{{type}}</mat-option>
                        </ng-container>
                    </mat-select>
                    <mat-error *ngIf="formData.get('industry_type')?.invalid && formData.get('industry_type')?.touched">
                        Industry Type is required
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="form-group col-md-12">
                <label>Size of the Company <span class="text-danger">*</span></label>
                <mat-form-field class="w-100">
                    <mat-select [formControlName]="'size_of_company'" placeholder="Select Size of the Company">
                        <ng-container *ngFor="let size of sizeOfCompany">
                            <mat-option [value]="size">{{size}}</mat-option>
                        </ng-container>
                    </mat-select>
                    <mat-error
                        *ngIf="formData.get('size_of_company')?.invalid && formData.get('size_of_company')?.touched">
                        Size of the Company is required
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="form-group col-md-12">
                <label>Service Required <span class="text-danger">*</span></label>
                <mat-form-field class="w-100">
                    <mat-select [formControlName]="'services_required'" placeholder="Select Service">
                        <ng-container *ngFor="let service of serviceRequired">
                            <mat-option [value]="service">{{service}}</mat-option>
                        </ng-container>
                    </mat-select>
                    <mat-error
                        *ngIf="formData.get('services_required')?.invalid && formData.get('services_required')?.touched">
                        Service is required
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="form-group col-md-12">
                <label for="message">Your Message <span class="text-danger">*</span></label>
                <textarea rows="4" id="message" formControlName="message" class="form-control"
                    placeholder="Type here..."
                    [ngClass]="{ 'is-invalid': formData.get('message')?.invalid && formData.get('message')?.touched }"></textarea>
                <div *ngIf="formData.get('message')?.invalid && formData.get('message')?.touched">
                    <small *ngIf="formData.get('message')?.errors?.['required']" class="text-danger">
                        Message is required.
                    </small>
                </div>
            </div>
            <div class="col-md-12">
                <button type="submit" name="submit" class="btn btn-contact-bg">Submit
                    Form</button>
            </div>
        </div>
    </form>
</div>